/* Custom fonts below */
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond&family=Ubuntu:wght@300;400;500&display=swap');

@font-face {
  font-family: 'fma';
  src:  url('../fonts/fonticon/fma.eot?aqti7i');
  src:  url('../fonts/fonticon/fma.eot?aqti7i#iefix') format('embedded-opentype'),
    url('../fonts/fonticon/fma.ttf?aqti7i') format('truetype'),
    url('../fonts/fonticon/fma.woff?aqti7i') format('woff'),
    url('../fonts/fonticon/fma.svg?aqti7i#fma') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-fma-"], [class*=" icon-fma-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fma' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fma-favicon:before {
  content: "\e900";
}
.icon-fma-home:before {
  content: "\e901";
}
.icon-fma-mail:before {
  content: "\e902";
}
.icon-fma-plus:before {
  content: "\e903";
}
.icon-fma-file:before {
  content: "\e904";
}
