@media print {
    body {
        background-color :#fff;
        font-family : sans-serif;
        font-size :12pt;
    }

    a {
        text-decoration: none !important;
    }

    .header-wrapper {
        box-shadow: none;
    }

    .navbar-toggler,
    .fixed-top .ml-auto,
    .navbar-header,
    .small-col,
    .footer,
    .cloud-spacer,
    .share-social,
    .btn-social-share,
    .btn-social-email,
    .article-header nav {
        display: none;
    }

    .large-col {
        flex: 0 0 94%;
        max-width: 94%;
    }

    .summary-tab-wrapper {
        .nav {
            display: none;
        }

        .tab-content {
            & > .tab-pane {
                display: block !important;
                opacity: 1 !important;

                &:last-child {
                    margin-top: 32px;
                }
            }
        }
    }

    figure {
        page-break-inside:avoid;
    }
}