// Push Home Type A
.pushHome {
  .extra-link.right {
    a {
      justify-content: flex-end;
    }
  }

  a:hover {
    text-decoration: none;
  }

  &__image-wrapper {
    margin-top: -3rem;

    @include media-breakpoint-down(md) {
      margin-top: $spacer;
    }
  }

  &__wrapper {
    .extra-link a {
      justify-content: flex-end;
      @include media-breakpoint-down(md) {
        justify-content: flex-start;
      }
    }
    &.left {
      flex-direction: row-reverse;

      .extra-link a {
        justify-content: flex-start;
      }
    }
  }
}
