.article-bloc-title {
    font-size: 20px;
    font-family: $font-sans;
}

.summary-tab-wrapper,
.keyword-wrapper {
    margin-top: 1rem;
    padding: 1.5rem;
    background-color: #eef1f5;
}

.summary-bloc {
    color: $theme-dark-color;


    .nav-item {
        font-size: 18px;

        a.active {
            color: $primary-color;
            font-weight: 500;
        }
    }
}

.keywords-bloc {
    a {
        color: $primary-color;

        &:hover {
            text-decoration: underline;
        }
    }
}