/* Custom variables below */

// Override Variables
$font-sans:                     'Ubuntu', sans-serif;
$border-color:                  #d4e3fc;

// New Variables
$font-serif:                    'EB Garamond', serif;

$pager-w:                       3rem;
