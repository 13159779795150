.download-pdf-wrapper {
    border: 1px solid $border-color;
    padding: 1.5rem;

    a {
        color: $primary-color;
        font-size: 1.25rem;
        display: flex;
        align-items: center;
    }

    svg {
        width: 40px;
        height: 40px;
    }

    span {
        margin: 0 2rem;
        line-height: 1.2;
    }
}