.article-header {
    margin-top: 2rem;
}

.article-title {
    h1 {
        font-size: 1.5rem;
        position: relative;
        margin-bottom: 2rem;

        &:before {
            content: '';
            height: 2px;
            width: 120px;
            display: block;
            background-color: $primary-color;
            position: absolute;
            bottom: -23px;
            left: 50%;
            transform: translateX(-50%);
        }

        @include media-breakpoint-up(lg) {
            font-size: 2rem;
        }
    }

    .image-text-horizontal & {
        font-weight: bold;
    }
}

.articlelayout {
    .large-col {
        .rte-text h2 {
            font-family: $font-sans;
            font-size: 20px !important;
            color: #000 !important;
        }
    }

    .rte-text {
        h3 {
            font-family: $font-sans;
            font-weight: 500;
            font-size: 22px;
            margin-bottom: 1.5rem;
            color: $theme-dark-color;
            position: relative;

            &:after {
                content: '';
                width: 100px;
                height: 3px;
                background-color: #9fafc1;
                position: absolute;
                left: 0;
                bottom: -15px;
            }
        }

        .text-mll-small {
            font-size: 13px;
            line-height: 1.5;
            margin-top: 13px;
        }

        p {
            text-align: justify;
        }

        h2 {
            font-size: 20px;
            color: #000;
        }
    }
}

.subtitle {
    text-align: center;
    color: $primary-color;
    font-family: $font-serif;
    font-size: 1.25rem;
    line-height: 1;

    @include media-breakpoint-up(lg) {
        font-size: 1.5rem;
    }
}

.article-list-block {
    padding: 1.5rem;
    background-color: #eff4f9;
    font-family: $font-serif;

    .description {
        p {
            line-height: 2;
        }

        a {
            color: inherit !important;
        }
    }

    .list-title {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            line-height: 1;
            right: .75rem;
            top: 50%;
            transform: translateY(-50%);
            height: 18px;
            width: 18px;
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 31.444 31.444' style='enable-background:new 0 0 31.444 31.444;' xml:space='preserve'%3E%3Cpath style='fill:%23FFFFFF;' d='M1.119,16.841c-0.619,0-1.111-0.508-1.111-1.127c0-0.619,0.492-1.111,1.111-1.111h13.475V1.127 C14.595,0.508,15.103,0,15.722,0c0.619,0,1.111,0.508,1.111,1.127v13.476h13.475c0.619,0,1.127,0.492,1.127,1.111 c0,0.619-0.508,1.127-1.127,1.127H16.833v13.476c0,0.619-0.492,1.127-1.111,1.127c-0.619,0-1.127-0.508-1.127-1.127V16.841H1.119z' /%3E%3C/svg%3E");
        }
    }
}