@use "sass:math";

body {
    font-family: $font-sans;
}

h1,
h2,
h3,
h4 {
    font-family: $font-serif;
    line-height: 1;
}

p {
    line-height: 1.5;
}

strong {
    font-weight: 600;
}

.vCenter {
    display: flex;
    align-items: center;
}

.btn,
.form-control {
    &:focus {
        outline: none;
        box-shadow: none;
    }
}

input,
button,
.btn,
.form-control {
    &:focus {
        outline: none;
        box-shadow: 0;
    }
}

a {
    &,
    &:hover,
    p &:hover,
    h3 &:hover {
        text-decoration: none;
    }
}

// Menu Toggle
.navbar-toggler {
    position: relative;
    border-color: transparent;
    border-radius: 100%;
    width: 50px;
    height: 50px;
    z-index: 1000;
    flex-shrink: 0;
    @include transition($transition-navbar);

    &:focus {
        outline: none;
    }

    .icon-bar {
        position: absolute;
        top: 50%;
        height: 1px;
        left: 50%;
        width: 50%;
        background-color: $navbar-toggler-color;
        @include translate3d(-50%, 0, 0);
        @include transition($transition-navbar);

        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $navbar-toggler-color;
            border-radius: 1px;
        }
        &:before {
            top: -7px;
        }

        &:after {
            top: 7px;
        }
    }

    &:not(.collapsed) {
        .icon-bar {
            background: transparent !important;

            &:before{
                @include transition($transition-navbar);
                -moz-transform: rotate(45deg) translate(5px,5px);
                -webkit-transform: rotate(45deg) translate(5px,5px);
                -o-transform: rotate(45deg) translate(5px,5px);
                -ms-transform: rotate(45deg) translate(5px,5px);
                transform: rotate(45deg) translate(5px,5px);
            }

            &:after{
                @include transition($transition-navbar);
                -moz-transform: rotate(-45deg) translate(5px,-5px);
                -webkit-transform: rotate(-45deg) translate(5px,-5px);
                -o-transform: rotate(-45deg) translate(5px,-5px);
                -ms-transform: rotate(-45deg) translate(5px,-5px);
                transform: rotate(-45deg) translate(5px,-5px);
            }
        }
    }
}

.cloud-liner {
    &:after {
        border-color: $primary-color;
    }
}

.lead {
    font-size: 1rem;
    font-weight: 400;
}

.row-overlay:after {
    background-color: rgba(0,0,0,.7);
}

.figure.media-image .contentwrapper .above-content-wrapper {
    padding: 0 .5rem;
    background-color: rgba(0,0,0,.2);
}

// Cloud
.is-desktop .cloud-edit.add-padding .navbar{
padding-left: 0 !important;
}

@include media-breakpoint-up(lg) {
  .is-desktop .cloud-edit.add-padding .navbar .navbar-primary {
    padding-left: 3rem !important;
  }
}

.is-desktop .cloud-edit.add-padding .header-wrapper.sticky .navbar .navbar-primary {
  padding-left: 0 !important;
}

.is-desktop .cloud-edit.add-padding .header-wrapper {
    padding-left: 80px;
}

.cloud-ui-wrapper .image-list .item .figure {
    height: 121px !important;
}

.btn {
  text-transform: uppercase;

  &.btn-outline {
    border: 1px solid;
  }

  &.btn-w-100 {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  &.with-picto {
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .image-picto {
    height: 1rem;
  }

  .btn-group-lg > &, &.btn-lg {
    padding: math.div($spacer, 2) $spacer * 1.25;
    font-size: 1.125rem;
  }

  &.input-group-text {
    padding: 2px 10px !important;
  }

  &.disabled {
    opacity: 1;
  }
}

.cloud-liner.xs {
  &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 4rem;
      border-top: 2px solid $primary-color;
      @include transform(translateX(-50%));
  }

  .has-bg-color.color-primary &:after {
    border-top-color: $white;
  }
}

h1, h2, h3, h4, h5, h6, .is-h1, .is-h2, .is-h3 {
  color: initial;
  line-height: 1.2;
}

h5 {
  color: initial;
  font-size: 1rem;
}

h1, .is-h1 {
  font-size: 3.5rem;

  @include media-breakpoint-down(md) {
    font-size: 2.5rem;
  }
}

h2, .is-h2 {
  font-weight: 300;
}

.row-overlay {
  > [class*="container"] {
      .is-mobile & {
          z-index: 9980;
      }
  }
}

.contentwrapper {
  .textwrapper .lead {
    font-size: 1rem;

    &:not(figcaption) p {
      display: flex;
      justify-content: flex-end;

      &:after {
        content: '\e903';
        font-family: 'fma';
        font-size: 24px;
        line-height: 1;
        margin-left: 5px;
        color: $white !important;
      }
    }
  }
}

.row-overlay {
  &:after {
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0.4) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 60%,rgba(0,0,0,0.4) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.4) 60%,rgba(0,0,0,0.4) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 );
  }
}

// autoblock
h4.autoblock-title {
  &, & * {
  font-family: $font-serif;
  color: $primary-color;
  font-size: 1.25rem;

    &:hover {
      text-decoration: none;
      color: darken($primary-color, 0.5);
    }
  }

  & + .abstract {
    line-height: 1.2;
  }
}

.autoblock-vertical {
  .image-text-vertical {
    border: 1px solid $secondary-color;
    margin-bottom: $spacer * 2;
    padding: 0 !important;
  }

  .col-md-8 {
    padding-top: $spacer;
    padding-bottom: $spacer;
  }

  .only-text {
    padding: math.div($spacer, 2) 0;

    h4.autoblock-title {
      &, & * {
        font-family: $font-sans;
        font-size: 1rem;
      }
    }
  }
}

.autoblock-horizontal {
  .row {
    justify-content: stretch;
  }

  .image-text-horizontal {
    box-shadow: 0 0 16px 0 rgba(#000, .1);
    transition: $transition-base;
    height: 100%;
    position: relative;

    &:hover {
      box-shadow: 0 0 16px 0 rgba(#000, .25);
    }

    a:hover {
      text-decoration:none;
    }

    i {
      font-size: 1.75rem;
      margin: $spacer;
    }

    .abstract {
      line-height: 1.4;
      font-size: .85rem;
    }
  }
}

.autoblock-fma-wrapper {
  h4 {
    &, & * {
      color: $primary-color !important;
      font-size: 1.2rem;
      line-height: 1.2;
    }
  }
}

figure.push-image {
  padding-top: 66%;
  background-position: center;
}

.footer {
  .logo {
    margin-bottom: 0;
    min-width: 200px;
  }

  .footer-copyright {
    font-size: .85rem;
  }

  p {
    margin-top: 0 !important;
  }

  .paragraph {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.navbar-primary {
  .nav-item {
    position: relative;
    font-size: 1rem;

    .nav-link {
      line-height: 1;

      &:hover {
        text-decoration: none !important;
      }

      &:after {
        content: '';
        height: 3px;
        position: absolute;
        left: 0;
        width: 0;
        background-color: $primary-color;
        @include transition($transition-navbar);
        bottom: -38px;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    &.active, &:hover {
      .nav-link {
        &:after {
          width: calc(100% - 1rem);
        }

        &[href="/"]:after {
          width: 100%;
        }
      }
    }
  }
}

.navbar {
  &.sticky {
    .navbar-primary .nav-item .nav-link {
      &:after {
        bottom: -22px;
      }
    }
  }
}

// Cloud
.has-sub-elements .cloud-edit-content .cloud-left.is-in-content {
  top: 0 !important;
  right: 0 !important;
}

@include media-breakpoint-down(md) {
  .cloud-liner {
      min-height: $liner-min-h;
  }
}

.sidebar-layout {
  .small-col {
    background-color: $secondary-color;
    padding: $spacer;
    align-self: flex-start;
  }

  h1, .is-h1 {
    font-size: 2.5rem;
  }
}

.rootContentSet {
  .cloudcontentset {
    background-position-x: 50%;
  }
}

@include media-breakpoint-down(md) {
  .navbar-header .navbar-primary.navigation-default {
    &.collapse.show, &.collapsing {
      box-shadow: 0 1px 2px 0 hsla(0,0%,63%,.3) !important;
    }
  }
}

.text-default-size {
  font-size: 1rem !important;
}

.download-pdf-block {
  .block-label {
    font-family: $font-serif;
    font-size: 1.125rem;
    color: $primary-color;
  }
}

.card-block .card-body,
.autoblock-text-wrapper {
  padding-bottom: 4.5rem;

  .customIcon-plus {
    position: absolute;
    line-height: 1;
    left: 50%;
    bottom: 42px;
    transform: translate(-50%, 1rem);
    height: 28px;
    width: 28px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 31.444 31.444' style='enable-background:new 0 0 31.444 31.444;' xml:space='preserve'%3E%3Cpath style='fill:%23556893;' d='M1.119,16.841c-0.619,0-1.111-0.508-1.111-1.127c0-0.619,0.492-1.111,1.111-1.111h13.475V1.127 C14.595,0.508,15.103,0,15.722,0c0.619,0,1.111,0.508,1.111,1.127v13.476h13.475c0.619,0,1.127,0.492,1.127,1.111 c0,0.619-0.508,1.127-1.127,1.127H16.833v13.476c0,0.619-0.492,1.127-1.111,1.127c-0.619,0-1.127-0.508-1.127-1.127V16.841H1.119z' /%3E%3C/svg%3E");
  }

  .small-col &:after {
    display: none;
  }
}

.small-col .autoblock-text-wrapper .customIcon-plus {
  display: none;
}

.image-text-vertical {
  h4 a {
    font-size: 18px;
    color: $primary-color !important;
    font-family: $font-serif;
  }

  .metadata-wrapper {
    font-size: 14px;
    font-family: $font-sans;
    color: #9fafc1;
  }
}

.bb-content .form-group .searchbar-wrapper {
  .input-group {
    height: auto;

    input {
      height: 3rem;
    }
  }
}

.logo-sponsor {
  max-width: 120px;
  height: auto;

  @include media-breakpoint-down(md) {
    &-wrapper {
      align-items: center;
      justify-content: space-between;
    }
  }

  @include media-breakpoint-up(lg) {
    &:first-child {
      max-width: 100px;
      margin-bottom: 5px;
    }

    &-wrapper {
      flex-direction: column;
    }
  }
}

#keywordsAccordion {
  .card-header {
    padding: 0;
    position: relative;

    a {
      display: block;
      padding: .75rem 1.25rem;

      &:after {
        content: '';
        height: 1.5rem;
        width: 1.5rem;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='11' viewBox='0 0 21 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.66 2.74871L10.66 10.7487C10.4775 10.909 10.2429 10.9974 9.99999 10.9974C9.75709 10.9974 9.52248 10.909 9.33999 10.7487L0.339993 2.74871C0.15416 2.57021 0.0443991 2.32687 0.0335951 2.06943C0.0227911 1.81199 0.111778 1.56031 0.282002 1.36687C0.452226 1.17344 0.690547 1.05317 0.947275 1.03116C1.204 1.00914 1.45932 1.08708 1.65999 1.24871L9.99999 8.65871L18.34 1.24871C18.4373 1.15519 18.5526 1.08236 18.6789 1.0346C18.8051 0.986835 18.9398 0.965129 19.0746 0.970789C19.2095 0.976449 19.3418 1.00936 19.4637 1.06753C19.5855 1.12571 19.6942 1.20794 19.7834 1.30928C19.8726 1.41062 19.9403 1.52897 19.9826 1.65719C20.0248 1.78541 20.0406 1.92085 20.0291 2.05535C20.0175 2.18985 19.9789 2.32062 19.9155 2.43978C19.852 2.55894 19.7651 2.66403 19.66 2.74871Z' fill='%23231F20'/%3E%3C/svg%3E%0A");
        background-size: .875rem;
        background-repeat: no-repeat;
        background-position: center;
        display: block;
        transition: $btn-transition;
        position: absolute;
        right: 14px;
        top: 10px;
      }

      &.collapsed {
        &:after {
          transform: rotate(180deg);
        }
      }
    }

  }
}

.cke_float {
  z-index: 10001 !important;
}

.rte-text {
  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
}
