.autoblock-vertical {
    .only-text {
        border-bottom: 0;
        margin-bottom: 1.5rem;

        .autoblock-title a span {
            font-family: $font-serif;
            line-height: 1.3;
            font-size: 1rem;
            color: initial;
        }
    }
}