/* Custom theme below */

$primary-color:             #556893;
$secondary-color:           #eff4f9;
$text-color:                #232323;
$background-color:          #FFFFFF;

$theme-dark-color:          #283755;
$white:                     #FFFFFF;


$theme-colors: (
    "primary": $primary-color,
    "secondary": $secondary-color,
    "dark": $theme-dark-color,
    "white": $white
)