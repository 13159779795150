.article-information {
    padding-left: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: .25rem solid $border-color;
    font-size: 1.25rem;

    .text-primary {
        margin-bottom: 1rem;
        font-family: $font-serif;
    }

    li {
        margin-bottom: .5rem;

        label {
            margin-bottom: 0;
            font-size: 16px;
        }

        p,
        a {
            color: $primary-color;
            font-size: 18px;
        }

        a {
            font-size: 16px;
        }
    }
}